import React, { useState } from 'react'
import Image from 'next/image'
import styles from './Subscribe.module.scss'
import Cookies from 'js-cookie'

const Subscribe = () => {
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
  const [submissionMessage, setSubmissionMessage] = useState<string>('')

  const hutk = Cookies.get('hubspotutk')

  const [formErrors, setFormErrors] = useState<string[]>([])

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()

    const target = event.target as typeof event.target & {
      email: { value: string }
    }

    // Get data from the form.
    const data = {
      email: target.email.value,
      hutk: hutk ? hutk : '',
    }

    // Send the data to the server in JSON format.
    const JSONdata = JSON.stringify(data)

    // API endpoint where we send form data.
    const endpoint = '/api/footerform'

    // Form the request for sending data to the server.
    const options = {
      // The method is POST because we are sending data.
      method: 'POST',
      // Tell the server we're sending JSON.
      headers: {
        'Content-Type': 'application/json',
      },
      // Body of the request is the JSON data we created above.
      body: JSONdata,
    }

    // Send the form data to our forms API on Vercel and get a response.
    const response = await fetch(endpoint, options)

    // Get the response data from server as JSON.
    // If server returns the name submitted, that means the form works.
    const result = await response.json()

    if (result.data) {
      setFormErrors([result.data])
    } else {
      setFormSubmitted(true)
      setSubmissionMessage('Thank you for subscribing')
    }
  }

  return (
    <div className={styles.form}>
      <div className={styles.errors}>
        {formErrors.map((error, index) => {
          return <div key={`error-${index}`}>{error}</div>
        })}
      </div>

      {!formSubmitted ? (
        <form onSubmit={handleSubmit}>
          <div className={styles.emailField}>
            <input
              type="email"
              name="email"
              placeholder="Enter Your Email"
              required
              inputMode="email"
              id="id_email"
            />

            <button type="submit" name="subscribe" className={styles.button}>
              <span className={styles.linkArrow}>
                <Image src="/arrow-white.svg" layout="fill" alt="" />
              </span>
            </button>
          </div>
        </form>
      ) : (
        <div className={styles.formSubmitted}>{submissionMessage}</div>
      )}
    </div>
  )
}

export default Subscribe
