import { useState } from 'react'

import { useWindowSize } from 'usehooks-ts'
import { useHasMounted } from './useHasMounted'
import constants from '../styles/_constants.module.scss'

export const useIsDesktop = (): boolean => {
  const hasMounted = useHasMounted()
  const { width } = useWindowSize()

  if (!hasMounted) {
    return true
  }

  if (width == 0) {
    return true
  }
  const isDesktop = width > parseInt(constants.tabletMin)

  return isDesktop
}

export const useIsMobile = (): boolean => {
  const hasMounted = useHasMounted()
  const { width } = useWindowSize()

  if (!hasMounted) {
    return false
  }

  if (width == 0) {
    return false
  }

  const isMobile = width <= parseInt(constants.tabletMin)
  return isMobile
}
