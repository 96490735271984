import { API_BASE } from '../siteConfig'

import { widont } from './typography'

export const getAPI = () => {
  return API_BASE
}

export function createMarkup(markup, skip_widont = false) {
  if (!skip_widont) {
    markup = widont(markup)
  }
  return { __html: markup }
}
