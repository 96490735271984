import gsap from 'gsap/dist/gsap'
import React, { cloneElement, useRef, useEffect, useLayoutEffect } from 'react'
import { useRouter } from 'next/router'
// import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group'
import { useIsMobile } from '../../../hooks/useIsDesktop'
import { useHasMounted } from '../../../hooks/useHasMounted'
import styles from './PageTransition.module.scss'
import variables from '../../../styles/_constants.module.scss'

type PageTransitionPropsTypes = {
  node: React.ReactElement
  animation: string
  transitioning: boolean
  style: object
  in: boolean
  onEntered: Function
  onExited: Function
}

const getTimeout = (animation: string) => {
  return 1000
}

const onEntering = (el: HTMLDivElement | null, isMobile: boolean) => {
  if (el) {
    gsap.fromTo(
      el,
      { opacity: 0 },
      {
        opacity: 1,
        duration: () => (isMobile ? 0.5 : 0.75),
      }
    )
  }
}

const onExiting = (
  el: HTMLDivElement | null,
  path: string,
  isMobile: boolean
) => {
  if (el) {
    gsap.to(el, {
      opacity: 0,
      duration: () => (isMobile ? 0.5 : 0.75),
    })
  }

  const pathId = path.split('#')
  if (pathId.length > 0) {
    window.setTimeout(
      () => {
        const id = pathId[1]
        const top =
          document.getElementById(id)?.getBoundingClientRect().top || 0
        window.scrollTo({
          top: top - 100,
          left: 0,
          behavior: 'smooth',
        })
      },
      isMobile ? 600 : 1100
    )
  }
}

const PageTransition = (props: PageTransitionPropsTypes) => {
  const {
    node,
    animation,
    transitioning,
    style,
    in: inProp,
    onEntered,
    onExited,
  } = props

  const hasMounted = useHasMounted()

  const transitionRef = useRef(null)

  const router = useRouter()

  const isMobile = useIsMobile()

  if (!hasMounted) {
    return (
      <div
        style={{
          ...style,
        }}
        ref={transitionRef}
      >
        {cloneElement(node, { transitioning })}
      </div>
    )
  }

  return (
    <Transition
      in={inProp}
      onEntered={onEntered}
      onExited={onExited}
      onEntering={() => onEntering(transitionRef.current, isMobile)}
      onExit={() => onExiting(transitionRef.current, router.asPath, isMobile)}
      timeout={getTimeout(animation)}
    >
      <div
        style={{
          ...style,
        }}
        ref={transitionRef}
      >
        {cloneElement(node, { transitioning })}
      </div>
    </Transition>
  )
}

export default PageTransition
