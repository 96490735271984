import gsap from 'gsap/dist/gsap'

import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  createRef,
  forwardRef,
  useLayoutEffect,
} from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Image from 'next/image'
import styles from './Nav.module.scss'
import Footer from '../footer/Footer'
import { useScroll, useScrollY } from '../../hooks/useScroll'
import { useWindowSize } from 'usehooks-ts'
import { useIsMobile } from '../../hooks/useIsDesktop'

import { useLockedBody } from 'usehooks-ts'
import { create } from 'domain'

type ActiveLinkProps = {
  title: string
  href: string
  setActive: (current: HTMLLIElement) => void
  handleClick: (event: React.SyntheticEvent, current: HTMLLIElement) => void
  target?: string
}

const ActiveLink = forwardRef<HTMLLIElement, ActiveLinkProps>(
  function ActiveLink(props, ref) {
    const { title, href } = props

    const router = useRouter()

    const isActive = router.pathname == href

    if (isActive && ref && 'current' in ref && ref.current) {
      props.setActive(ref.current)
    }

    const getClasses = isActive ? styles.navItemActive : styles.navItem

    const handleLinkClick = (e: React.SyntheticEvent) => {
      if (ref && 'current' in ref && ref.current) {
        props.handleClick(e, ref.current)
      }
    }
    return (
      <li ref={ref} className={getClasses}>
        <Link href={href}>
          <a onClick={(e) => handleLinkClick(e)} target={props.target}>
            <span className={styles.linkInner}>{title}</span>
          </a>
        </Link>
      </li>
    )
  }
)

type NavInnerProps = {
  theme: string
  handleHamburgerOpen: (isOpen: boolean) => void
  hamburgerActive: boolean
}

const NavInner = forwardRef<HTMLDivElement, NavInnerProps>(function NavInner(
  props,
  headerRef
) {
  const { theme } = props
  const border = useRef<HTMLDivElement | null>(null)
  const navListRef = useRef<HTMLDivElement | null>(null)
  const batteries = createRef<HTMLLIElement>()
  const manufacturing = createRef<HTMLLIElement>()
  const company = createRef<HTMLLIElement>()
  // const industries = createRef<HTMLLIElement>()
  // const sustainability = createRef<HTMLLIElement>()
  const news = createRef<HTMLLIElement>()
  const careers = createRef<HTMLLIElement>()
  const contact = createRef<HTMLLIElement>()

  const [locked, setLocked] = useLockedBody()

  const activeLinks = useMemo(
    () => [
      {
        href: '/manufacturing',
        title: 'Manufacturing',
        ref: manufacturing,
        target: '',
      },
      {
        href: '/batteries',
        title: 'Battery Technology',
        ref: batteries,
        target: '',
      },
      // {
      //   href: '/industries',
      //   title: 'Industries',
      //   ref: industries,
      //   target: '',
      // },
      // {
      //   href: '/sustainability',
      //   title: 'Sustainability',
      //   ref: sustainability,
      //   target: '',
      // },
      {
        href: '/news',
        title: 'Newsroom',
        ref: news,
        target: '',
      },
      {
        href: '/company',
        title: 'Company',
        ref: company,
        target: '',
      },
      {
        href: '/careers',
        title: 'Careers',
        ref: careers,
        target: '',
      },
      {
        href: '/contact',
        title: 'Contact',
        ref: contact,
        target: '',
      },
    ],
    [
      batteries,
      careers,
      company,
      // industries,
      manufacturing,
      news,
      contact,
      // sustainability,
    ]
  )

  const router = useRouter()

  const { width, height } = useWindowSize()
  const isMobile = useIsMobile()
  const [currentRef, setCurrentRef] = useState<any>(null)
  const scrollY = useScrollY()
  const [logoLoaded, setLogoLoaded] = useState<boolean>(false)
  const isActive = theme == 'light' || router.pathname != '/' || scrollY > 400

  useEffect(() => {
    // Going to home page or static page
    if (
      router.asPath == '/' ||
      !activeLinks.find(
        (link) => link.href == '/' + router.asPath.split('/')[1]
      )
    ) {
      setCurrentRef(null)
      gsap.to(border.current, {
        opacity: 0,
        duration: 0.25,
      })
    }
  }, [router, activeLinks])

  useEffect(() => {
    // Will update the border position on changes to state
    if (theme == 'dark' && navListRef.current && border.current && currentRef) {
      gsap.to(border.current, {
        x: currentRef.offsetLeft,
        opacity: 1,
        width: currentRef.offsetWidth,
        duration: 0.75,
        ease: 'power3.inOut',
        overwrite: true,
      })
    }
  }, [theme, currentRef, width])

  useEffect(() => {
    // Update current ref on router update
    if (theme == 'dark' && activeLinks.length > 0) {
      const currentRef = activeLinks.filter(
        (link) => link.href == '/' + router.asPath.split('/')[1]
      )
      if (currentRef.length > 0) {
        setCurrentRef(currentRef[0].ref.current)
      }
    }
  }, [theme, router, activeLinks])

  useEffect(() => {
    if (navListRef.current && isMobile) {
      const t1 = gsap.timeline({ paused: true })
      t1.fromTo(
        navListRef.current,
        {
          xPercent: 103,
        },
        {
          xPercent: 0,
          duration: 0.5,
        }
      )

      if (props.hamburgerActive) {
        t1.play()
        setLocked(true)
      }
    } else if (!isMobile) {
      setLocked(false)
      gsap.to(navListRef.current, {
        xPercent: 0,
        duration: 0.5,
      })
    }
  }, [props.hamburgerActive, isMobile, setLocked])

  const handleHomeClick = (e: React.SyntheticEvent) => {
    if (router.asPath == '/') {
      e.preventDefault()
      gsap.to(window, { duration: 1, scrollTo: 0 })
    }
  }

  const handleNavClick = (
    e: React.SyntheticEvent,
    el: HTMLLIElement | null
  ) => {
    // toggleHamburger(false)
    if (props.hamburgerActive) {
      if (navListRef.current && isMobile) {
        setLocked(false)
        const t1 = gsap.timeline()
        activeLinks.forEach((link) => {
          t1.to(
            link.ref.current,
            {
              color: '#000',
              duration: 0.5,
            },
            '<'
          )
        })

        t1.to(
          el,
          {
            color: '#AADDDF',
            duration: 0.5,
          },
          '<'
        )
        t1.to(navListRef.current, {
          xPercent: 103,
          duration: 0.5,
          delay: 0.25,
          onComplete: () => {
            activeLinks.forEach((link) => {
              gsap.set(link.ref.current, {
                color: '',
              })
            })
            gsap.set(el, { color: '' })
            gsap.set(navListRef.current, {
              scrollTop: 0,
            })
            props.handleHamburgerOpen(false)
          },
        })
      }
    }
  }

  const handleHamburgerClick = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    if (props.hamburgerActive) {
      if (navListRef.current && isMobile) {
        setLocked(false)
        gsap.to(navListRef.current, {
          xPercent: 103,
          duration: 0.5,
          onComplete: () => {
            props.handleHamburgerOpen(false)
          },
        })
      }
    } else {
      props.handleHamburgerOpen(!props.hamburgerActive)
    }
  }

  const setStateActive = (el: HTMLLIElement) => {
    // LOAD FOR FIRST TIME
    if (!currentRef && el && navListRef.current) {
      gsap.set(border.current, {
        x: () => el.offsetLeft,
        opacity: 1,
        width: () => el.offsetWidth,
        delay: 1,
      })
    }
  }

  return (
    <header
      className={`header ${
        theme == 'light' ? styles.navContainerLight : styles.navContainerDark
      } ${
        router.asPath == '/' && theme == 'dark' && scrollY < 100
          ? styles.navContainerOpacity
          : ''
      }`}
      ref={headerRef}
    >
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Link href="/">
            <a
              onClick={(e) => {
                handleHomeClick(e)
                handleNavClick(e, null)
              }}
            >
              <Image
                className={styles.logo}
                src={'/logo-dark.svg'}
                layout="fill"
                alt="Sakuu Logo"
                style={{ opacity: logoLoaded ? 1 : 0 }}
                priority
                onLoadingComplete={() => setLogoLoaded(true)}
              />
            </a>
          </Link>
          {isMobile && (
            <div
              className={styles.hamburgerContainer}
              onClick={(e) => handleHamburgerClick(e)}
            >
              <div
                className={`${styles.hamburger} ${
                  props.hamburgerActive ? styles.active : ''
                }`}
              >
                <div className={styles.hamburgerInner}></div>
              </div>
            </div>
          )}
        </div>

        <nav
          className={`${styles.navListContainer} ${
            props.hamburgerActive ? styles.navListContainerActive : ''
          }`}
          ref={navListRef}
        >
          {theme == 'dark' && (
            <div className={styles.navBorder} ref={border}></div>
          )}
          <ul
            className={`${styles.navList} ${
              props.hamburgerActive ? styles.navListActive : ''
            }`}
          >
            {activeLinks.map((link, index) => (
              <ActiveLink
                key={index}
                href={link.href}
                title={link.title}
                ref={link.ref}
                handleClick={(e, el) => handleNavClick(e, el)}
                setActive={(el) => setStateActive(el)}
                target={link.target}
              />
            ))}
          </ul>

          {isMobile && theme == 'dark' && (
            <Footer handleClick={(e, el) => handleNavClick(e, el)} />
          )}
        </nav>
      </div>
    </header>
  )
})

const Nav = () => {
  const [isHeaderActive, setHeaderActive] = useState(true)
  const headerRef = useRef<HTMLDivElement>(null)
  const scrollY = useScrollY()
  const [lastScrollY, setLastScrollY] = useState(0)
  const [scrollUpHeader, setScrollUpHeader] = useState(false)
  const [isHamburgerOpen, setHamburgerOpen] = useState(false)
  const { width, height } = useWindowSize()
  const isMobile = useIsMobile()
  const lightThemeRef = useRef<HTMLDivElement>(null)
  const [showLight, setShowLight] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    if (headerRef.current) {
      gsap.set(headerRef.current, { opacity: 1 })
    }
  }, [])

  useEffect(() => {
    if (headerRef.current && isHamburgerOpen) {
      gsap.to(headerRef.current, {
        yPercent: 0,
        duration: 0.1,
        overwrite: true,
        ease: 'power3.inOut',
      })
    }
  })

  return (
    <>
      <NavInner
        theme="dark"
        ref={headerRef}
        handleHamburgerOpen={setHamburgerOpen}
        hamburgerActive={isHamburgerOpen}
      ></NavInner>
    </>
  )
}

export default Nav
