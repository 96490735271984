import { createContext, useContext } from 'react'

export type SharedDataType = {
  site_meta: {
    title: string
    description: string
    meta_image?: string
  }
  contact: {
    title: string
    detail: string
  }
  footer: {
    contact_detail: string
    subscribe_detail: string
    address: string
  }
  socialMedia: {
    twitter: string
    instagram: string
    linkedin: string
  }
}

export type GlobalContentContextType = {
  data: SharedDataType
  setData: (sharedData: SharedDataType) => void
}

export const GlobalContentContext = createContext<GlobalContentContextType>({
  data: {
    site_meta: {
      title: '',
      description: '',
      meta_image: '',
    },
    contact: {
      title: '',
      detail: '',
    },
    footer: {
      contact_detail: '',
      subscribe_detail: '',
      address: '',
    },
    socialMedia: {
      twitter: '',
      instagram: '',
      linkedin: '',
    },
  },
  setData: () => {},
})

export const useGlobalContentContext = (): GlobalContentContextType =>
  useContext(GlobalContentContext)
