import React from 'react'
import { useRouterScroll } from '@moxy/next-router-scroll'
// import { isHistoryEntryFromPopState } from '@moxy/react-page-swapper'
import PageSwapper from '@moxy/react-page-swapper'
import PageTransition from '../pageTransition/PageTransition'
import { useRouter } from 'next/router'

type AppInnerProps = {
  Component: React.ComponentType
  pageProps: any
  history: any
}

export const AppInner = (props: AppInnerProps) => {
  const { Component, pageProps, history } = props
  const { updateScroll } = useRouterScroll()
  const mode = 'out-in'

  const router = useRouter()

  return (
    <>
      <PageSwapper
        mode={mode}
        style={{ width: '100%', minHeight: '120vh' }}
        updateScroll={updateScroll}
        animation="fade"
        nodeKey={router.asPath}
        node={<Component {...pageProps} />}
      >
        {(props: any) => <PageTransition {...props} history={history} />}
      </PageSwapper>
    </>
  )
}
