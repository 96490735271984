import '../styles/defaults.scss'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import { useEffect, useRef, useState } from 'react'
import { RouterScrollProvider } from '@moxy/next-router-scroll'
import { Router, useRouter } from 'next/router'
import { AppInner } from '../components/general/appInner/AppInner'

import { useTransitionFix } from '../hooks/useTransitionFix'

import {
  GlobalContentContext,
  GlobalContentContextType,
  SharedDataType,
} from '../context/GlobalContentContext'

import Head from 'next/head'

import GenericPageLayout from '../components/general/genericPageLayout/GenericPageLayout'

function MyApp(props: AppProps) {
  // const containerRef = useRef<HTMLDivElement | null>(null)
  const router = useRouter()
  const [history, setHistory] = useState<string[]>([])

  // if (typeof history !== 'undefined') {
  //   history.scrollRestoration = 'manual'
  // }

  const [globalData, setGlobalData] = useState<SharedDataType>({
    site_meta: {
      title: '',
      description: '',
      meta_image: '',
    },
    contact: {
      title: '',
      detail: '',
    },
    footer: {
      subscribe_detail: '',
      contact_detail: '',
      address: '',
    },
    socialMedia: {
      twitter: '',
      instagram: '',
      linkedin: '',
    },
  })

  const globalvalues: GlobalContentContextType = {
    data: globalData,
    setData: setGlobalData,
  }

  useEffect(() => {
    document.fonts.ready.then(() => {
      document.body.classList.add('is-fonts-loaded')
    })

    history.push(router.asPath)

    // reset the postion on refresh
    window.scrollTo(0, 0)
    // don't pass anything into dep array, it only needs to run once
  }, [history, router.asPath])

  useTransitionFix((props.pageProps as any).error !== undefined)

  return (
    <div id="wrap">
      <Head>
        <meta property="og:type" content="website" />
        <meta property="og:url" content={'https://sakuu.com/'} key="url" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/fav/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/fav/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/fav/favicon-16x16.png"
        />
        <link rel="manifest" href="/fav/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/fav/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <style>
          {`.responsive-image {opacity: 0;  transition: opacity 300ms; visibility: hidden;} 
            #content {opacity: 0;min-height: 100vh;} header {opacity: 0;} footer { opacity: 0;}
            
            `}
        </style>
      </Head>

      <GlobalContentContext.Provider value={globalvalues}>
        <GenericPageLayout>
          <RouterScrollProvider>
            <AppInner {...props} history={history} />
          </RouterScrollProvider>
        </GenericPageLayout>
      </GlobalContentContext.Provider>
    </div>
  )
}

export default MyApp
