import React, { useEffect } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import {
  useGlobalContentContext,
  SharedDataType,
} from '../../context/GlobalContentContext'
import { useIsMobile } from '../../hooks/useIsDesktop'
import styles from './Footer.module.scss'

import { createMarkup } from '../../utils/helpers'
import Subscribe from '../subscribe/Subscribe'

type FooterProps = {
  handleClick?: (event: React.SyntheticEvent, current: HTMLLIElement) => void
}

const Footer = (props: FooterProps) => {
  const { data } = useGlobalContentContext()

  const isMobile = useIsMobile()

  const handleLinkClick = (e: React.SyntheticEvent) => {
    if (props.handleClick) {
      props.handleClick(e, e.currentTarget as HTMLLIElement)
    }
  }

  return (
    <footer className={`footer ${styles.footer}`}>
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.signupContainer}>
            <div
              className={styles.signupTitle}
              dangerouslySetInnerHTML={createMarkup(data.footer.contact_detail)}
            ></div>
            <div
              className={styles.signupDetail}
              dangerouslySetInnerHTML={createMarkup(
                data.footer.subscribe_detail
              )}
            ></div>
            <Subscribe />
          </div>
        </div>
        <div className={styles.logoContainer}>
          <Link href="/">
            <a onClick={(e) => handleLinkClick(e)}>
              <Image
                className={styles.logo}
                src={'/logo.svg'}
                layout="fill"
                alt="sakuu logo"
              />
            </a>
          </Link>
        </div>
        <div className={styles.addressContainer}>
          <div
            className={styles.address}
            dangerouslySetInnerHTML={createMarkup(data.footer.address)}
          ></div>
        </div>
        <nav className={styles.navContainer}>
          <ul className={styles.navList}>
            <li>
              © {new Date().getFullYear()} Sakuu Corporation. All Rights
              Reserved.
            </li>
            <li onClick={(e) => handleLinkClick(e)}>
              <Link href="/faq">
                <a>FAQ</a>
              </Link>
            </li>
            <li onClick={(e) => handleLinkClick(e)}>
              <Link href="/privacy">
                <a>Privacy</a>
              </Link>
            </li>
          </ul>

          <ul className={styles.socialList}>
            <li>
              <a
                href={data.socialMedia.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <Image src={'/twitter-icon.svg'} layout="fill" alt="twitter" />
              </a>
            </li>
            <li>
              <a
                href={data.socialMedia.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src={'/instagram-icon.svg'}
                  layout="fill"
                  alt="instagram"
                />
              </a>
            </li>
            <li>
              <a
                href={data.socialMedia.linkedin}
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src={'/linkedin-icon.svg'}
                  layout="fill"
                  alt="linkedin"
                />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  )
}

export default Footer
