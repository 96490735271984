import React, { useEffect } from 'react'

import Nav from '../../nav/Nav'
import Footer from '../../footer/Footer'
import styles from './GenericPageLayout.module.scss'
import {
  useGlobalContentContext,
  SharedDataType,
} from '../../../context/GlobalContentContext'

export type GenericPageLayoutProps = {
  meta_title: string
  meta_description: string
  meta_image: string
  title: string
  originalResponse: any
} & { children: React.ReactNode } & { globalData: SharedDataType }

type LayoutProps = {
  children: React.ReactNode
}

const GenericPageLayout = (props: LayoutProps) => {
  const { children } = props

  return (
    <>
      <Nav />
      <main id="content" className={styles.container}>
        {children}
      </main>
      <Footer />
    </>
  )
}

export default GenericPageLayout
